/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/Container';
import HowItWorks from './HowItWorks';
import DiscountSquares from './DiscountSquares';
import Image from '../../components/Image';
import Card from './Card';
import FaqDrop from '../../components/FaqDrop';
// Style
import {
  BodyTextBig,
  CTASection,
  DotsDivider,
  FlexWrap,
  GlobalHero,
  GraySection,
  HeroHeading,
  ImageWrap,
  SectionTitle,
  SmallSectionTitle,
  TextWrap,
  WhiteSection,
} from '../../components/Global';
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';
import { CTA } from '../../styles/GlobalStyles';
import constants from '../../constants';


const GlobalHeroForAffiliateProgram = styled(GlobalHero)`
  .hero-text {
    width: 40%;
  }
  .hero-img {
    .square-wrapper {
      display: flex;
      margin: 0 80px 0 0;
      justify-content: center
    }
  }

  ${MediaBreakDown.lg`
    .hero-text {
      width: 100%;
    }
    .hero-img {
      .square-wrapper {
        margin-bottom: 120px;
      }
    }
  `}
`;
GlobalHeroForAffiliateProgram.displayName = 'GlobalHeroForAffiliateProgram';

const GuidelineContainer = styled(Container)`
  ${SectionTitle} {
    margin-bottom: 30px;
  }
  ${BodyTextBig} {
    text-align: center;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 50px 0 0 0;
  }

  ${MediaBreakDown.lg`
    .card-wrapper {
      flex-direction: column;
      margin: 30px 30px 0 30px;

      & > div {
        margin: 0 0 30px 0;
      }
    }
  `}
`;
GuidelineContainer.displayName = 'GuidelineContainer';

const ImageWrapper = styled(ImageWrap)`
  max-width: 360px;
  margin: auto;

  ${MediaBreakDown.xl`
    max-width: 440px;
  `}
  ${MediaBreakDown.lg`
    margin-bottom: 40px;
    max-width: 300px;
  `}
`;
ImageWrapper.displayName = 'ImageWrapper';

const TestimonialWrapper = styled.div`
  .comment {
    font-size: 36px;
    line-height: 50px;
    color: ${theme.body.light};
    display: block;
    position: relative;
    margin: 0 0 30px 0;

    ::before {
      content: '“';
      font-size: 96px;
      position: absolute;
      left: -40px;
    }
    ::after {
      content: '”';
      font-size: 96px;
      position: absolute;
      bottom: -10px;
    }
  }
  .author {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: ${theme.textColor.blackText};
    display: block;
    margin: 0 0 20px 0;
  }
  .profile-link {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${theme.textColor.blackText};
    display: block;
  }

  ${MediaBreakDown.lg`
    .comment {
      font-size: 28px;
      line-height: 50px;

      ::before {
        left: 0px;
      }
    }
    .author {
      font-size: 28px;
      line-height: 34px;
    }
    .profile-link {
      font-size: 20px;
      line-height: 24px;
    }
  `}
`;
TestimonialWrapper.displayName = 'TestimonialWrapper';

const FaqsSection = styled.div`
  width: 100%;
  max-width: 767px;
  margin: 80px auto 0;

  ${SmallSectionTitle} {
    text-align: center;
  }
  a {
    color: ${theme.hover.hoverBlue};
  }
`;
FaqsSection.displayName = 'FaqsSection';


const doItems = [
  (
    <React.Fragment>
      Use branding from our
      {' '}
      <a href="/branding-guidelines/">brand page</a>
      .
    </React.Fragment>
  ),
  'Place your links in blogs, comparisons, newsletters, etc.',
  'Repurpose assests from our website and social channels',
  'Share your unique link on TikTok, Instagram, Twitter, etc.',
];
const dontItems = [
  'Use your unique link on sites with adult or illegal content.',
  (
    <React.Fragment>
      Promote Workast without out
      {' '}
      <a href="/branding-guidelines/">branding guidelines</a>
      .
    </React.Fragment>
  ),
  'Use fraudulent marketing tactics.',
  'Abuse the program.',
];
const faqsItems = [
  { 
    question: 'What is the Workast Affiliate Program and how can I apply?', 
    answer: '<p>The Workast affiliate program offers an opportunity for bloggers, community owners, entrepreneurs, influencers, and creators to share the benefits of Workast with their audience and earn financial rewards in return.</p><p>Ready to join? Apply now! We review affiliate applications weekly. Simply provide us with some information about yourself and your audience, and we\'ll evaluate and approve your application.</p>' 
  },
  { 
    question: 'What qualifies as a successful conversion?', 
    answer: '<p>New paid subscriptions resulting from a referral link will be eligible for affiliate commission, tracked via a 60-day cookie. Commissions become due 30 days after the subscriber\'s initial payment.</p><p>Commissions are only applicable to new workspace creations initiated through a referral link. The commission is a one-time payment and becomes eligible for payment when they upgrade to a paid plan, regardless of the time elapsed between the creation of the workspace and the upgrade.</p><p>If the user already has an active workspace and upgrades, there won\'t be any commissions. Also, if a subscriber cancels their subscription within 30 days of the initial payment, no commission will be issued.</p><p>Here are a few examples to illustrate when commissions are paid and when they are not:</p><ul><li>Paid Scenario: a user clicks on an affiliate link, creates a new workspace, and later upgrades to a paid plan. In this case, regardless of how much time passes between the creation of the workspace and its upgrade to a paid plan, the affiliate earns a commission.</li><li>Unpaid Scenario: a user clicks on an affiliate link but already has an active workspace. Even if they upgrade to a paid plan later, no commission will be paid.</li><li>Important Note: if a subscriber cancels their subscription within 30 days of the initial payment, no commission will be issued.</li></ul>' 
  },
  { 
    question: 'How can I create, track, and promote my affiliate link?', 
    answer: '<p>You can see your stats in your dashboard on Rewardful platform. Once we approve your application, the account will be created and login details will be provided. When you receive your unique link, you can promote it on TikTok, Instagram, Twitter, etc. Your message must include that you have a financially compensated relationship with Workast.</p>' 
  },
  { 
    question: 'What terms apply to the Workast Affiliate Program?', 
    answer: '<p>The Workast Affiliate program terms govern the guidelines and conditions for affiliates promoting and marketing Workast\'s products and services for a commission. Affiliates must comply with laws, use Workast\'s intellectual property appropriately, and follow the commission structure, which pays monthly based on sales made through a unique referral link. Prohibitions include self-referral and incentivizing purchases, and affiliates must ensure accurate and non-misleading promotion that does not harm Workast\'s reputation. For more information, see <a href="https://www.workast.com/workast-affiliate-program-participant-terms/" target="_blank">https://www.workast.com/workast-affiliate-program-participant-terms/</a>.</p>' 
  },
  { 
    question: 'How do I get paid?', 
    answer: '<p>Payments will be facilitated through the Rewardful platform, utilizing the PayPal user account you provide in the application form. There\'s no minimum payout requirement.</p>' 
  },
  { 
    question: 'Have more questions?', 
    answer: '<p>If you have more questions you can reach us at <a href="mailto:affiliates@workast.com">affiliates@workast.com</a>.</p>' 
  },
  { 
    question: 'Partners', 
    answer: '<a href="https://www.rewardful.com/" target="_blank">https://www.rewardful.com/</a>' 
  }
];



const AffiliateProgram = (props) => {
  const {
    location,
  } = props;


  return (
    <Layout location={location}>
      <SEO
        title="Workast's Affiliate Program"
        description="Become an affiliate and earn a $100 commission for every new customer you bring to Workast, the best Work Management Platform."
        imageName="affiliate-discount.png"
      />
      <Container>
        <GlobalHeroForAffiliateProgram>
          <div className="hero-text">
            <HeroHeading>
              Join Workast&apos;s
              Affiliate Program
            </HeroHeading>
            <BodyTextBig>
              Become an affiliate and earn $100 USD commission for every new
              customer you bring to Workast, the best Work Management Platform.
            </BodyTextBig>
            <div className="button-wrap">
              <CTA
                target="_blank"
                href={constants.URLS.AFFILIATE}
              >
                Apply now
              </CTA>
            </div>
          </div>
          <div className="hero-img">
            <div className="square-wrapper">
              <DiscountSquares />
            </div>
            <CTA
              target="_blank"
              href={constants.URLS.AFFILIATE}
            >
              Apply now
            </CTA>
          </div>
        </GlobalHeroForAffiliateProgram>
      </Container>


      {/* DIVIDER */}
      <DotsDivider margin="0 0 40px 0" />


      {/* HOW IT WORKS */}
      <GraySection>
        <SectionTitle>
          How it works
        </SectionTitle>
        <HowItWorks />
      </GraySection>


      {/* TESTIMONIAL */}
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="top">
            <ImageWrapper width="45%">
              <Image name="affiliate-program-testimonial.png" alt="Testimonial" />
            </ImageWrapper>
            <TextWrap width="50%">
              <TestimonialWrapper>
                <span className="comment">
                  Discovering Workast&apos;s Affiliate Program as an avid user was a game-changer.
                  With a generous $100 commission on every subscription,
                  I can recommend a product I believe in and earn money from the comfort of my home.
                </span>
                <span className="author">Angeli Yuson</span>
                <span className="profile-link">
                  <a href="https://tiktok.com/@angeli.yuson">tiktok.com/@angeli.yuson</a>
                </span>
              </TestimonialWrapper>
            </TextWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>

      {/* GUIDELINES */}
      <GraySection>
        <GuidelineContainer>
          <SectionTitle>
            Guidelines
          </SectionTitle>
          <BodyTextBig>
            What you need to know to run a succesfull campaign
          </BodyTextBig>
          <div className="card-wrapper">
            <Card
              isDo
              items={doItems}
            />
            <Card
              isDo={false}
              items={dontItems}
            />
          </div>
        </GuidelineContainer>
      </GraySection>


      {/* FAQs SECTION */}
      <Container>
        <FaqsSection>
          <SmallSectionTitle mb="65px">Frequently Asked Questions</SmallSectionTitle>
          {
            faqsItems.map(item => (
              <FaqDrop
                key={item.question}
                question={item.question}
                answer={item.answer}
                disableToggle
              />
            ))
          }
        </FaqsSection>
      </Container>


      {/* CTA */}
      <CTASection maxWidth="initial">
        <Container>
          <SectionTitle>
            Ready to become a Workast Affiliate?
          </SectionTitle>
          <CTA
            target="_blank"
            href={constants.URLS.AFFILIATE}
          >
            Join
          </CTA>
        </Container>
      </CTASection>
    </Layout>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
AffiliateProgram.defaultProps = {
  data: {},
};
AffiliateProgram.propTypes = {
  data: PropTypes.shape({
    contentfulSolutionPage: PropTypes.shape({
      team: PropTypes.string,
      heroTitle: PropTypes.string,
      heroText: PropTypes.shape({
        heroText: PropTypes.string,
      }),
      heroImage: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      features: PropTypes.arrayOf(
        PropTypes.shape({
          tagline: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.shape({
            description: PropTypes.string,
          }),
          image: PropTypes.shape({}),
          linkText: PropTypes.string,
          linkUrl: PropTypes.string,
        }),
      ),
      blogArticles: PropTypes.arrayOf(
        PropTypes.shape({
          featuredImage: PropTypes.shape({}),
          title: PropTypes.string,
          id: PropTypes.string,
          slug: PropTypes.string,
        }),
      ),
      reviews: PropTypes.arrayOf(
        PropTypes.shape({

        }),
      ),
    }),
  }),
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default AffiliateProgram;
