/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Style
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';


const DiscountSquaresWrapper = styled.div`
  width: 375px;
  height: 375px;
  position: relative;

  .square-yellow,
  .square-red,
  .square-blue {
    width: 375px;
    height: 375px;
    border-radius: 20px;
  }
  .square-yellow {
    background: #F2C94C;
  }
  .square-red {
    background: #EB5757;
    position: absolute;
    top: 80px;
    left: 80px;
  }
  .square-blue {
    background: #2F80ED;
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .discount-number {
      color: ${theme.textColor.white};
      font-size: 120px;
      font-weight: 700;
      line-height: 140px;
    }
    .discount-message {
      color: ${theme.textColor.white};
      width: 70%;
      text-align: center;
    }
  }

  ${MediaBreakDown.lg`
    width: 250px;
    height: 250px;

    .square-yellow,
    .square-red,
    .square-blue {
      width: 250px;
      height: 250px;
    }

    .square-blue {
      .discount-number {
        font-size: 70px;
        line-height: 70px;
      }
    }
  `}
`;
DiscountSquaresWrapper.displayName = 'DiscountSquaresWrapper';


const DiscountSquares = () => (
  <DiscountSquaresWrapper>
    <div className="square-yellow" />
    <div className="square-red" />
    <div className="square-blue">
      <span className="discount-number">$100</span>
      <span className="discount-message">
        Commission per paid subscription referred by
        you for as long as the subscription is active
      </span>
    </div>
  </DiscountSquaresWrapper>
);


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
DiscountSquares.defaultProps = {};
DiscountSquares.propTypes = {};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default DiscountSquares;
